import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { GatsbyImage } from "gatsby-plugin-image"
import Inner from "../../layout/pageInner/Inner"
import LinkWrapper from "../../../utils/linkWrapper"

const InfoRow = ({ show = "brands", cols = 6 }) => {
  // Extract graphql fields
  const {
    localWpGraphQlPages: {
      home_page: { stockRow, publicationsRow },
    },
  } = useStaticQuery(INFO_ROW_QUERY)

  // Either show brands or publications
  const data = show === "brands" ? stockRow : publicationsRow

  return (
    <Row>
      <Inner>
        <TextWrap>
          <Title>{data.title || title}</Title>
          <SubTitle>{data.subTitle || subTitle}</SubTitle>
        </TextWrap>
      </Inner>
      {data.logos ? <Logos logos={data.logos} cols={cols} /> : children}
    </Row>
  )
}

const Logos = ({ logos, cols }) => {
  return (
    <Inner>
      <Grid className={cols === 3 ? "cols-3" : ""}>
        {logos &&
          logos.map((logo, idx) => {
            const image = (
              <GatsbyImage
                image={
                  logo.image.imageFile.localFile.childImageSharp.gatsbyImageData
                }
                key={idx}
                alt={`logo-${idx}`}
              />
            )

            // wrap with link if there is one
            if (logo.link && logo.link !== "#") {
              return (
                <LinkWrapper key={idx} to={logo.link} target={"_blank"}>
                  {image}
                </LinkWrapper>
              )
            }
            // only return image
            return image
          })}
      </Grid>
    </Inner>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  padding: 40px 0;
  align-items: center;
  justify-items: center;
  img {
    mix-blend-mode: multiply;
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    grid-template-columns: 1fr;
  }
  &.cols-3 {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
    @media (max-width: 865px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`

export default InfoRow

const Row = styled.div`
  background: #fff;
  padding: 60px 0;
`

const TextWrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.h2`
  color: #223247;
  border-bottom: 1px solid red;
`
const SubTitle = styled.h3`
  color: #223247;
  font-size: 16px;
  margin-top: 10px;
`

const INFO_ROW_QUERY = graphql`
  {
    localWpGraphQlPages(pageId: { eq: 194 }) {
      home_page {
        # Brands logos, the "We stock the following brands" section
        stockRow {
          subTitle
          title
          logos {
            link
            image {
              sourceUrl
              mediaItemId
              imageFile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 50, width: 150, layout: FIXED)
                  }
                }
              }
            }
          }
        }
        # Publication logos, the "We've got some people talking" section
        publicationsRow {
          subTitle
          title
          logos {
            link
            image {
              sourceUrl
              mediaItemId
              imageFile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 70, width: 383, layout: FIXED)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
