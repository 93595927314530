import React from "react"
import styled from "styled-components"
import Layout from "../../zzz/layout"
import SEO from "../../components/SEO/seo"
import Heading from "../../zzz/organisms/heading"
import Inner from "../../zzz/layout/pageInner/Inner"
import { graphql, useStaticQuery } from "gatsby"
import Quote from "../../components/AboutUs/Quote"
import InfoRow from "../../zzz/organisms/InfoRow"
import { getFluid, getImage } from "../../lib/util"
import { GatsbyImage } from "gatsby-plugin-image"

const PhilanthropyPage = ({ location }) => {
  const data = useStaticQuery(PAGE)
  const logos = data.homePage.home_page.publicationsRow
  const about = data.page
  const seo = data.page.seo
  const philanthropy = data.page.philanthropy
  let imageCounter = 0

  return (
    <Layout location={location}>
      <SEO title="Contact Us" location={location} post_id={456} seo={seo} />
      <Heading
        subTitle={about.aboutUs.aboutUsSubTitle}
        image={getImage(about)}
        location={location}
      />
      <Wrap>
        <Inner>
          <h2>
            {
              philanthropy.philanthropyOpeningContent
                .philanthropyOpeningContentTitle
            }
          </h2>
          <span
            dangerouslySetInnerHTML={{
              __html:
                philanthropy.philanthropyOpeningContent
                  .philanthropyOpeningContentParagraph,
            }}
          />
        </Inner>
      </Wrap>
      <Quote quote={philanthropy.philanthropyQuote} />
      <Wrap>
        <InnerWrapper>
          {philanthropy.philanthropyCases.map((cases, index) => {
            let imageOrderSide = imageCounter % 2
            return (
              <div key={index} className="philanRows">
                <ContentSide
                  className={`${imageOrderSide ? "leftSide" : "rightSide"} ${
                    cases.philanthropyCasesImages ? "halfSpace" : "wholeSpace"
                  }`}
                >
                  <h3>{cases.philanthropyCasesTitle}</h3>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: cases.philanthropyCasesContent,
                    }}
                  />
                </ContentSide>
                {cases.philanthropyCasesImages &&
                  cases.philanthropyCasesImages.map((casesImg, caseIndex) => {
                    imageOrderSide = imageCounter++ % 2
                    return (
                      <ImageSide
                        className={imageOrderSide ? "rightSide" : "leftSide"}
                        key={`${index}_${caseIndex}`}
                      >
                        <GatsbyImage
                          image={
                            casesImg.philanthropyCasesImageItem.imageFile
                              .localFile.childImageSharp.gatsbyImageData
                          }
                          className="philanImg"
                          key={`${index}_${caseIndex}`}
                        />
                      </ImageSide>
                    )
                  })}
              </div>
            )
          })}
        </InnerWrapper>
      </Wrap>
      {/* <InfoRow data={{ logos }} /> */}
    </Layout>
  )
}

export default PhilanthropyPage

const Wrap = styled.div`
  background: #f6f6f6;
  padding: 50px 0;

  .philanRows {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 50px 50px 50px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      padding: 20px 20px;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      padding: 10px 10px;
    }
  }

  .philanRows:nth-of-type(even) {
    background: white;
  }

  .philanRows:nth-of-type(odd) {
    background: #f6f6f6;
  }

  .philanRows:nth-of-type(1) {
    margin-top: -50px;
  }

  .leftSide {
    order: 1;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      order: 1;
    }
  }
  .rightSide {
    order: 2;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      order: 1;
    }
  }
  .philanImg {
    max-height: 750px;
    overflow: hidden;
    width: auto;
    margin: auto;
  }
  .philanRows .wholeSpace {
    margin: auto;
  }

  h2 {
    border-bottom: 2px solid red;
    margin-bottom: 20px;
    width: max-content;
    margin: 0 auto;
    margin-bottom: 40px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 100%;
    }
  }
  h3 {
    font-weight: 400;
  }
`

const InnerWrapper = styled.div`
  margin: 0;
  padding: 0;
`

const ContentSide = styled.div`
  margin: auto;
  &.wholeSpace {
    width: 80%;
    align-content: center;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 100%;
    }
  }
  &.halfSpace {
    width: 50%;
    display: inline-block;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 100%;
    }
  }
  &.halfSpace.rightSide {
    padding-left: 50px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      padding: 0px;
    }
  }

  &.halfSpace.leftSide {
    padding-right: 50px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      padding: 0px;
    }
  }
`

const ImageSide = styled.div`
  width: 50%;
  height: 100%;
  margin: auto;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    padding-top: 20px;
  }
`

const PAGE = graphql`
  {
    page: localWpGraphQlPages(pageId: { eq: 456 }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      aboutUs {
        aboutUsSubTitle
      }
      philanthropy {
        philanthropyQuote
        philanthropyOpeningContent {
          philanthropyOpeningContentTitle
          philanthropyOpeningContentParagraph
        }
        philanthropyCases {
          philanthropyCasesTitle
          philanthropyCasesContent
          philanthropyCasesImages {
            philanthropyCasesImageItem {
              sourceUrl
              mediaItemId
              modified
              imageFile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
      featuredImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
    homePage: localWpGraphQlPages(pageId: { eq: 194 }) {
      home_page {
        publicationsRow {
          logos {
            link
            image {
              sourceUrl
              mediaItemId
              modified
              imageFile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
